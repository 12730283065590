// @flow

import './index.less';

import React from 'react';

type Props = {
  title: String,
};

const TitleWithSeparator = ({ title }: Props): React$Node => {
  return (
    <div className="TitleWithSeparator">
      <div className="TitleText">{title}</div>
      <div className="Separator" />
    </div>
  );
};

export default TitleWithSeparator;
