// @flow

import './index.less';

import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { OutboundLink } from 'gatsby-plugin-segment-analytics';
import React from 'react';

// eslint-disable-next-line import/no-unresolved
import LogoImg from '_images/logo-ritual-black.svg';

const Navbar = (): React$Node => {
  const { t } = useTranslation();

  return (
    <div className="Navbar">
      <Link to="/">
        <div className="Logo">
          <img src={LogoImg} alt="" />
          <p>
            Ritual <span>Fitness</span>
          </p>
        </div>
      </Link>
      <ul>
        <Link to="/" activeClassName="active">
          <li>{t('Common_Articles')}</li>
        </Link>
        <OutboundLink href="https://ritualgym.app.link/aaqlSF8Lxfb" target="_blank">
          <li>{t('Common_Gyms')}</li>
        </OutboundLink>
        <OutboundLink href="https://3i7k8.app.link/C8qhIB0Lxfb" target="_blank">
          <li>{t('Common_Apps')}</li>
        </OutboundLink>
      </ul>
    </div>
  );
};

export default Navbar;
