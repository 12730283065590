// @flow
import './index.less';

import { Layout } from 'antd';
import React from 'react';

import Navbar from '_components/Navbar';

const { Header, Content } = Layout;

type Props = {
  children?: React$Node,
};

const AppLayout = ({ children }: Props): React$Node => (
  <Layout>
    <Header className="Header">
      <div className="MaxWidthContainer">
        <Navbar />
      </div>
    </Header>

    <Content className="Content">
      <div className="MaxWidthContainer">{children}</div>
    </Content>

    {/* <Footer className="Footer"> */}
    {/*  <div className="MaxWidthContainer"> */}
    {/*    <AppFooter /> */}
    {/*  </div> */}
    {/* </Footer> */}
  </Layout>
);

export default AppLayout;
