// @flow

import './index.less';

import React from 'react';

type Props = {
  title: String,
};

const Tag = ({ title }: Props): React$Node => <span className="Tag">{title}</span>;

export default Tag;
