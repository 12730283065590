// @flow

import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Helmet from 'react-helmet';

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site: sanitySiteSettings {
      title
      description
      keywords
      author {
        name
      }
    }
  }
`;

type Props = {
  description: String,
  lang: String,
  meta: String,
  keywords: String,
  title: String,
  image: Object,
};

function SEO({ description, lang = 'en', meta = [], keywords = [], title, image }: Props) {
  const { site } = useStaticQuery(detailsQuery) || {};

  const metaDescription = description || site.description || '';
  const siteTitle = site.title || '';
  const siteAuthor = site.author?.name || '';
  const metaImage = image?.asset.url;

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      titleTemplate={title === siteTitle ? '%s' : `%s | ${siteTitle}`}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image',
          content: metaImage,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: siteAuthor,
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
      ]
        .concat(
          keywords && keywords.length > 0
            ? {
                name: 'keywords',
                content: keywords.join(', '),
              }
            : []
        )
        .concat(meta)}
    />
  );
}

export default SEO;
